import { useMemo } from 'react'
import { ORGANISATION } from 'src/constants/general'

export const useOrganisation = (): ORGANISATION => {
    const organisation = useMemo(() => {
        const env = process.env.REACT_APP_BRAND || 'switch'

        switch (env.toLowerCase()) {
            case 'metermate':
                return ORGANISATION.METER_MATE
            case 'manuflo':
                return ORGANISATION.MANUFLO
            case 'hydrowellness':
                return ORGANISATION.HYDRO_WELLNESS
            case 'joat':
                return ORGANISATION.JOAT
            case 'exprop':
                return ORGANISATION.EXPROP
            case 'nuleaf':
                return ORGANISATION.NULEAF
            case 'switch':
            default:
                return ORGANISATION.SWITCH
        }
    }, [])

    return organisation
}
