import { FC, Suspense, lazy } from 'react'
import { Navigate } from 'react-router-dom'
import SuspenseLoader from 'switch-fe-shared/components/Suspense/SuspenseLoader'
import { ROLE_ACTIONS } from 'src/constants/roles'
import AuthGuard from 'src/guards/AuthGuard'
import RouteGuard from 'src/guards/RouteGuard'
import BaseLayout from 'src/layouts/BaseLayout'
import SidebarLayout from 'src/layouts/SidebarLayout'
import Unverified from 'src/pages/Fallbacks/Status/Unverified/Unverified'
import pages from './routes'

const Loader = (Component: FC) => (props: any) => (
    <Suspense fallback={<SuspenseLoader />}>
        <Component {...props} />
    </Suspense>
)

//TODO Change any
const lazyRetry = function (componentImport: any): Promise<any> {
    return new Promise((resolve, reject) => {
        // check if the window has already been refreshed
        const hasRefreshed = JSON.parse(
            window.sessionStorage.getItem('retry-lazy-refreshed') || 'false'
        )
        // try to import the component
        componentImport()
            .then((component: any) => {
                window.sessionStorage.setItem('retry-lazy-refreshed', 'false') // success so reset the refresh
                resolve(component)
            })
            .catch((error: any) => {
                if (!hasRefreshed) {
                    // not been refreshed yet
                    window.sessionStorage.setItem(
                        'retry-lazy-refreshed',
                        'true'
                    ) // we are now going to refresh
                    return window.location.reload() // refresh the page
                }
                reject(error) // Default error behaviour as already tried refresh
            })
    })
}

// Pages
const Dashboard = Loader(
    lazy(() => lazyRetry(() => import('src/pages/Dashboard/pages/Dashboard')))
)
const Recons = Loader(
    lazy(() => lazyRetry(() => import('src/pages/Billing/pages/Recons')))
)
const Reports = Loader(
    lazy(() => lazyRetry(() => import('src/pages/Reporting/pages/Reports')))
)
const Register = Loader(
    lazy(() => lazyRetry(() => import('src/pages/Register/Register')))
)
const Login = Loader(
    lazy(() => lazyRetry(() => import('src/pages/Login/Login')))
)
const Integrations = Loader(
    lazy(() => lazyRetry(() => import('src/pages/Integrations/Integrations')))
)
const Payments = Loader(
    lazy(() => lazyRetry(() => import('src/pages/Payments/pages/Payments')))
)
const MeterJobs = Loader(
    lazy(() => lazyRetry(() => import('src/pages/Meters/pages/MeterJobs')))
)

//Application Groups
const ApplicationGroups = Loader(
    lazy(() =>
        lazyRetry(
            () => import('src/pages/ApplicationGroups/pages/ApplicationGroups')
        )
    )
)
const ApplicationGroupCreate = Loader(
    lazy(() =>
        lazyRetry(() => import('src/pages/ApplicationGroups/pages/Create'))
    )
)
const ApplicationGroupView = Loader(
    lazy(() =>
        lazyRetry(() => import('src/pages/ApplicationGroups/pages/View'))
    )
)

//Applications
const Applications = Loader(
    lazy(() =>
        lazyRetry(() => import('src/pages/Applications/pages/Applications'))
    )
)
const ApplicationCreate = Loader(
    lazy(() => lazyRetry(() => import('src/pages/Applications/pages/Create')))
)
const ApplicationView = Loader(
    lazy(() => lazyRetry(() => import('src/pages/Applications/pages/View')))
)
const ApplicationBulkSetup = Loader(
    lazy(() =>
        lazyRetry(() => import('src/pages/Applications/pages/BulkSetup'))
    )
)

// Accounts
const Accounts = Loader(
    lazy(() => lazyRetry(() => import('src/pages/Accounts/pages/Accounts')))
)
const AccountCreate = Loader(
    lazy(() => lazyRetry(() => import('src/pages/Accounts/pages/Create')))
)
const AccountView = Loader(
    lazy(() => lazyRetry(() => import('src/pages/Accounts/pages/View')))
)

// Tariffs
const Tariffs = Loader(
    lazy(() => lazyRetry(() => import('src/pages/Tariffs/pages/Tariffs')))
)
const TariffCreate = Loader(
    lazy(() => lazyRetry(() => import('src/pages/Tariffs/pages/Create')))
)
const TariffView = Loader(
    lazy(() => lazyRetry(() => import('src/pages/Tariffs/pages/View')))
)

// Utilities
const Utilities = Loader(
    lazy(() => lazyRetry(() => import('src/pages/Utilities/pages/Utilities')))
)
const UtilityCreate = Loader(
    lazy(() => lazyRetry(() => import('src/pages/Utilities/pages/Create')))
)
const UtilityView = Loader(
    lazy(() => lazyRetry(() => import('src/pages/Utilities/pages/View')))
)

// Sensors
const Sensors = Loader(lazy(() => lazyRetry(() => import('src/pages/Sensors'))))
const SensorView = Loader(
    lazy(() => lazyRetry(() => import('src/pages/Sensors/View')))
)

// Meters
const UnallocatedMeters = Loader(
    lazy(() =>
        lazyRetry(
            () => import('src/pages/UnallocatedMeters/pages/UnallocatedMeters')
        )
    )
)
const Meters = Loader(
    lazy(() => lazyRetry(() => import('src/pages/Meters/pages/Meters')))
)
const MeterCreate = Loader(
    lazy(() => lazyRetry(() => import('src/pages/Meters/pages/Create')))
)
const MeterView = Loader(
    lazy(() => lazyRetry(() => import('src/pages/Meters/pages/View')))
)

// Meter Points
const MeterPoints = Loader(
    lazy(() =>
        lazyRetry(() => import('src/pages/MeterPoints/pages/MeterPoints'))
    )
)
const MeterPointCreate = Loader(
    lazy(() => lazyRetry(() => import('src/pages/MeterPoints/pages/Create')))
)
const MeterPointView = Loader(
    lazy(() => lazyRetry(() => import('src/pages/MeterPoints/pages/View')))
)

// Gateways
const Gateways = Loader(
    lazy(() => lazyRetry(() => import('src/pages/Gateways/pages/Gateways')))
)
const GatewayView = Loader(
    lazy(() => lazyRetry(() => import('src/pages/Gateways/pages/View')))
)
const GatewayCreate = Loader(
    lazy(() => lazyRetry(() => import('src/pages/Gateways/pages/Create')))
)

// Billing Runs
const BillingRuns = Loader(
    lazy(() =>
        lazyRetry(() => import('src/pages/BillingRun/pages/BillingRuns'))
    )
)
const BillingRunCreate = Loader(
    lazy(() => lazyRetry(() => import('src/pages/BillingRun/pages/Create')))
)
const BillingRunView = Loader(
    lazy(() => lazyRetry(() => import('src/pages/BillingRun/pages/View')))
)

//Zones
const Zones = Loader(
    lazy(() => lazyRetry(() => import('src/pages/Zones/pages/Zones')))
)
const ZoneCreate = Loader(
    lazy(() => lazyRetry(() => import('src/pages/Zones/pages/Create')))
)
const ZoneView = Loader(
    lazy(() => lazyRetry(() => import('src/pages/Zones/pages/View')))
)

//Users
const Users = Loader(
    lazy(() => lazyRetry(() => import('src/pages/Users/pages/Users')))
)
const UserCreate = Loader(
    lazy(() => lazyRetry(() => import('src/pages/Users/pages/Create')))
)
const UserView = Loader(
    lazy(() => lazyRetry(() => import('src/pages/Users/pages/View')))
)

// Emails
const EmailIntegrationLogs = Loader(
    lazy(() =>
        lazyRetry(
            () =>
                import(
                    'src/pages/EmailIntegrationLogs/pages/EmailIntegrationLogs'
                )
        )
    )
)
const EmailIntegrationWhitelists = Loader(
    lazy(() =>
        lazyRetry(
            () =>
                import(
                    'src/pages/EmailIntegrationLogs/pages/EmailIntegrationWhitelist'
                )
        )
    )
)

// Other
const Map = Loader(
    lazy(() => lazyRetry(() => import('src/pages/Map/pages/Map')))
)
const Financials = Loader(
    lazy(() => lazyRetry(() => import('src/pages/Financials/pages/Financials')))
)
const ManualReadings = Loader(
    lazy(() =>
        lazyRetry(() => import('src/pages/ManualReadings/pages/ManualReadings'))
    )
)

// Status
const Status404 = Loader(
    lazy(() => import('src/pages/Fallbacks/Status/Status404/Status404'))
)
const Status500 = Loader(
    lazy(() => import('src/pages/Fallbacks/Status/Status500/Status500'))
)
const StatusComingSoon = Loader(
    lazy(() => import('src/pages/Fallbacks/Status/ComingSoon/ComingSoon'))
)
const StatusMaintenance = Loader(
    lazy(() => import('src/pages/Fallbacks/Status/Maintenance/Maintenance'))
)
const StatusSuccess = Loader(
    lazy(() => import('src/pages/Fallbacks/Status/Success/Success'))
)
const StatusFailure = Loader(
    lazy(() => import('src/pages/Fallbacks/Status/Failure/Failure'))
)
const StatusCancel = Loader(
    lazy(() => import('src/pages/Fallbacks/Status/Cancel/Cancel'))
)

const routes: any[] = [
    {
        path: '',
        element: <BaseLayout />,
        /**
         * All children within this element will NOT have a Sidebar and top Navbar
         * All children within this element does not need to be authenticated to access
         */
        children: [
            //#region Base
            {
                //Navigate to home when base routed to base path
                path: '/',
                element: <Navigate to={pages.home.path} replace />,
            },
            //#endregion Base
            //#region Auth
            {
                //All authentication routes
                //No navbars are shown as the user is not logged in
                path: pages.auth.root,
                children: [
                    {
                        path: '',
                        element: <Login />,
                    },
                    {
                        path: pages.auth.login.name,
                        element: <Login />,
                    },
                    {
                        path: pages.auth.register.name,
                        element: <Register />,
                    },
                ],
            },
            //#endregion Auth
            //#region Status
            {
                //All status routes
                path: pages.status.root,
                children: [
                    {
                        path: '',
                        element: <Navigate to="404" replace />,
                    },
                    {
                        path: pages.status.unverified.name,
                        element: <Unverified />,
                    },
                    {
                        path: pages.status.status404.name,
                        element: <Status404 />,
                    },
                    {
                        path: pages.status.status500.name,
                        element: <Status500 />,
                    },
                    {
                        path: pages.status.statusMaintenance.name,
                        element: <StatusMaintenance />,
                    },
                    {
                        path: pages.status.statusComingSoon.name,
                        element: <StatusComingSoon />,
                    },
                    //TODO: Maybe make paths that shows status within the sidebars as well
                    {
                        path: pages.status.statusSuccess.name,
                        element: <StatusSuccess />,
                    },
                    {
                        path: pages.status.statusFailure.name,
                        element: <StatusFailure />,
                    },
                    {
                        path: pages.status.statusCancel.name,
                        element: <StatusCancel />,
                    },
                ],
            },
            //#endregion Status
            //#region NotFound
            {
                path: '*',
                element: <Status404 />,
            },
            //#endregion NotFound
        ],
    },
    {
        path: '',
        element: (
            /**
             * All children with this element will have a Sidebar and top Navbar
             * AuthGuard checks that the user is logged in before granting access to its children pages
             */
            <AuthGuard>
                <SidebarLayout />
            </AuthGuard>
        ),
        children: [
            //#region Base
            {
                path: '',
                element: <Navigate to={pages.home.name} replace />,
            },
            //#endregion Base
            //#region Dashboard
            {
                path: pages.home.name,
                element: <Dashboard />,
            },
            //#endregion Dashboard
            {
                path: pages.applicationGroup.path,
                breadcrumb: 'Application Groups',
                children: [
                    {
                        path: '',
                        index: true,
                        element: (
                            <RouteGuard
                                allowedActions={ROLE_ACTIONS.READ}
                                subject="ApplicationGroups"
                            >
                                <ApplicationGroups />
                            </RouteGuard>
                        ),
                    },
                    {
                        path: ':id',
                        element: (
                            <RouteGuard
                                allowedActions={ROLE_ACTIONS.UPDATE}
                                subject="ApplicationGroups"
                            >
                                <ApplicationGroupView />
                            </RouteGuard>
                        ),
                    },
                    {
                        path: 'create',
                        element: (
                            <RouteGuard
                                allowedActions={ROLE_ACTIONS.CREATE}
                                subject="ApplicationGroups"
                            >
                                <ApplicationGroupCreate />
                            </RouteGuard>
                        ),
                    },
                ],
            },
            {
                path: pages.zones.path,
                children: [
                    {
                        path: '',
                        index: true,
                        element: (
                            <RouteGuard
                                allowedActions={ROLE_ACTIONS.READ}
                                subject="Zones"
                            >
                                <Zones />
                            </RouteGuard>
                        ),
                    },
                    {
                        path: ':id',
                        element: (
                            <RouteGuard
                                allowedActions={ROLE_ACTIONS.READ}
                                subject="Zones"
                            >
                                <ZoneView />
                            </RouteGuard>
                        ),
                    },
                    {
                        path: 'create',
                        element: (
                            <RouteGuard
                                allowedActions={ROLE_ACTIONS.CREATE}
                                subject="Zones"
                            >
                                <ZoneCreate />
                            </RouteGuard>
                        ),
                    },
                ],
            },
            {
                path: pages.application.path,
                children: [
                    {
                        path: '',
                        index: true,
                        element: (
                            <RouteGuard
                                allowedActions={ROLE_ACTIONS.READ}
                                subject="Applications"
                            >
                                <Applications />
                            </RouteGuard>
                        ),
                    },
                    {
                        path: ':id',
                        element: (
                            <RouteGuard
                                allowedActions={ROLE_ACTIONS.READ}
                                subject="Applications"
                            >
                                <ApplicationView />
                            </RouteGuard>
                        ),
                    },
                    {
                        path: 'create',
                        element: (
                            <RouteGuard
                                allowedActions={ROLE_ACTIONS.CREATE}
                                subject="Applications"
                            >
                                <ApplicationCreate />
                            </RouteGuard>
                        ),
                    },
                    {
                        path: 'bulk-setup',
                        element: (
                            <RouteGuard
                                allowedActions={ROLE_ACTIONS.MANAGE}
                                subject="all"
                            >
                                <ApplicationBulkSetup />
                            </RouteGuard>
                        ),
                    },
                ],
            },
            {
                path: pages.accounts.path,
                children: [
                    {
                        path: '',
                        index: true,
                        element: (
                            <RouteGuard
                                allowedActions={ROLE_ACTIONS.READ}
                                subject="Accounts"
                            >
                                <Accounts />
                            </RouteGuard>
                        ),
                    },
                    {
                        path: 'create',
                        element: (
                            <RouteGuard
                                allowedActions={ROLE_ACTIONS.CREATE}
                                subject="Accounts"
                            >
                                <AccountCreate />
                            </RouteGuard>
                        ),
                    },
                    {
                        path: ':id',
                        element: (
                            <RouteGuard
                                allowedActions={ROLE_ACTIONS.READ}
                                subject="Accounts"
                            >
                                <AccountView />
                            </RouteGuard>
                        ),
                    },
                ],
            },
            {
                path: pages.sensors.path,
                children: [
                    {
                        path: '',
                        index: true,
                        element: (
                            <RouteGuard
                                allowedActions={ROLE_ACTIONS.READ}
                                subject={'Sensors'}
                            >
                                <Sensors />
                            </RouteGuard>
                        ),
                    },
                    {
                        path: ':id',
                        element: (
                            <RouteGuard
                                allowedActions={ROLE_ACTIONS.READ}
                                subject="Sensors"
                            >
                                <SensorView />
                            </RouteGuard>
                        ),
                    },
                ],
            },
            {
                path: pages.meters.path,
                children: [
                    {
                        path: '',
                        index: true,
                        element: (
                            <RouteGuard
                                allowedActions={ROLE_ACTIONS.READ}
                                subject="Meters"
                            >
                                <Meters />
                            </RouteGuard>
                        ),
                    },
                    {
                        path: 'create',
                        element: (
                            <RouteGuard
                                allowedActions={ROLE_ACTIONS.CREATE}
                                subject="Meters"
                            >
                                <MeterCreate />
                            </RouteGuard>
                        ),
                    },
                    {
                        path: ':id',
                        element: (
                            <RouteGuard
                                allowedActions={ROLE_ACTIONS.READ}
                                subject="Meters"
                            >
                                <MeterView />
                            </RouteGuard>
                        ),
                    },
                    {
                        path: 'jobs',
                        element: (
                            <RouteGuard
                                allowedActions={ROLE_ACTIONS.READ}
                                subject="Jobs"
                            >
                                <MeterJobs />
                            </RouteGuard>
                        ),
                    },
                ],
            },
            {
                path: pages.meterpoints.path,
                breadcrumb: 'Meter Points',
                children: [
                    {
                        path: '',
                        index: true,
                        element: (
                            <RouteGuard
                                allowedActions={ROLE_ACTIONS.READ}
                                subject="MeterPoints"
                            >
                                <MeterPoints />
                            </RouteGuard>
                        ),
                    },
                    {
                        path: 'create',
                        element: (
                            <RouteGuard
                                allowedActions={ROLE_ACTIONS.CREATE}
                                subject="MeterPoints"
                            >
                                <MeterPointCreate />
                            </RouteGuard>
                        ),
                    },
                    {
                        path: ':id',
                        element: (
                            <RouteGuard
                                allowedActions={ROLE_ACTIONS.READ}
                                subject="MeterPoints"
                            >
                                <MeterPointView />
                            </RouteGuard>
                        ),
                    },
                ],
            },
            {
                path: pages.gateways.path,
                children: [
                    {
                        path: '',
                        index: true,
                        element: (
                            <RouteGuard
                                allowedActions={ROLE_ACTIONS.READ}
                                subject="Gateways"
                            >
                                <Gateways />
                            </RouteGuard>
                        ),
                    },
                    {
                        path: 'create',
                        element: (
                            <RouteGuard
                                allowedActions={ROLE_ACTIONS.CREATE}
                                subject="Gateways"
                            >
                                <GatewayCreate />
                            </RouteGuard>
                        ),
                    },
                    {
                        path: ':id',
                        element: (
                            <RouteGuard
                                allowedActions={ROLE_ACTIONS.READ}
                                subject="Gateways"
                            >
                                {/* <GatewayInfo /> */}
                                <GatewayView />
                            </RouteGuard>
                        ),
                    },
                    // {
                    //     path:':imei/logs',
                    //     element: (
                    //         <RouteGuard allowedActions={ROLE_ACTIONS.READ} subject='Gateways'>
                    //             <ResponseLogs />
                    //         </RouteGuard>
                    //     )
                    // },
                ],
            },
            {
                path: pages.utility.path,
                children: [
                    {
                        path: '',
                        index: true,
                        element: (
                            <RouteGuard
                                allowedActions={ROLE_ACTIONS.READ}
                                subject="ApplicationUtilities"
                            >
                                <Utilities />
                            </RouteGuard>
                        ),
                    },
                    {
                        path: 'create',
                        element: (
                            <RouteGuard
                                allowedActions={ROLE_ACTIONS.CREATE}
                                subject="ApplicationUtilities"
                            >
                                <UtilityCreate />
                            </RouteGuard>
                        ),
                    },
                    {
                        path: ':id',
                        element: (
                            <RouteGuard
                                allowedActions={ROLE_ACTIONS.READ}
                                subject="ApplicationUtilities"
                            >
                                <UtilityView />
                            </RouteGuard>
                        ),
                    },
                ],
            },
            {
                path: pages.payments.path,
                children: [
                    {
                        path: '',
                        index: true,
                        element: (
                            <RouteGuard
                                allowedActions={ROLE_ACTIONS.READ}
                                subject="Payments"
                            >
                                <Payments />
                            </RouteGuard>
                        ),
                    },
                    {
                        path: 'financials',
                        element: (
                            <RouteGuard
                                allowedActions={ROLE_ACTIONS.READ}
                                subject="Payments"
                            >
                                <Financials />
                            </RouteGuard>
                        ),
                    },
                ],
            },
            {
                path: pages.map.path,
                children: [
                    {
                        path: '',
                        index: true,
                        element: (
                            <RouteGuard
                                allowedActions={ROLE_ACTIONS.READ}
                                subject="Meters"
                            >
                                <Map />
                            </RouteGuard>
                        ),
                    },
                ],
            },
            {
                path: pages.manualReadings.path,
                children: [
                    {
                        path: '',
                        index: true,
                        element: (
                            <RouteGuard
                                allowedActions={ROLE_ACTIONS.MANAGE}
                                subject="UtilityData"
                            >
                                <ManualReadings />
                            </RouteGuard>
                        ),
                    },
                    {
                        path: 'whitelists',
                        element: (
                            <RouteGuard
                                allowedActions={ROLE_ACTIONS.MANAGE}
                                subject="all"
                            >
                                <EmailIntegrationWhitelists />
                            </RouteGuard>
                        ),
                    },
                ],
            },
            {
                path: pages.unallocatedMeters.path,
                children: [
                    {
                        path: '',
                        index: true,
                        element: (
                            <RouteGuard
                                allowedActions={ROLE_ACTIONS.MANAGE}
                                subject="all"
                            >
                                <Meters />
                            </RouteGuard>
                        ),
                    },
                ],
            },
            {
                path: pages.users.path,
                children: [
                    {
                        path: '',
                        index: true,
                        element: (
                            <RouteGuard
                                allowedActions={ROLE_ACTIONS.MANAGE}
                                subject="Users"
                            >
                                <Users />
                            </RouteGuard>
                        ),
                    },
                    {
                        path: 'create',
                        element: (
                            <RouteGuard
                                allowedActions={ROLE_ACTIONS.CREATE}
                                subject="Users"
                            >
                                <UserCreate />
                            </RouteGuard>
                        ),
                    },
                    {
                        path: ':id',
                        element: (
                            <RouteGuard
                                allowedActions={ROLE_ACTIONS.MANAGE}
                                subject="Users"
                            >
                                <UserView />
                            </RouteGuard>
                        ),
                    },
                ],
            },
            {
                path: pages.tariffs.path,
                children: [
                    {
                        path: '',
                        index: true,
                        element: (
                            <RouteGuard
                                allowedActions={ROLE_ACTIONS.READ}
                                subject="Tariffs"
                            >
                                <Tariffs />
                            </RouteGuard>
                        ),
                    },
                    {
                        path: 'create',
                        element: (
                            <RouteGuard
                                allowedActions={ROLE_ACTIONS.CREATE}
                                subject="Tariffs"
                            >
                                <TariffCreate />
                            </RouteGuard>
                        ),
                    },
                    {
                        path: ':id',
                        element: (
                            <RouteGuard
                                allowedActions={ROLE_ACTIONS.READ}
                                subject="Tariffs"
                            >
                                <TariffView />
                            </RouteGuard>
                        ),
                    },
                ],
            },
            {
                path: pages.billing.path,
                children: [
                    {
                        path: '',
                        index: true,
                        element: (
                            <RouteGuard
                                allowedActions={ROLE_ACTIONS.READ}
                                subject="BillingRuns"
                            >
                                <BillingRuns />
                            </RouteGuard>
                        ),
                    },
                    {
                        path: 'create',
                        element: (
                            <RouteGuard
                                allowedActions={ROLE_ACTIONS.CREATE}
                                subject="BillingRuns"
                            >
                                <BillingRunCreate />
                            </RouteGuard>
                        ),
                    },
                    {
                        path: ':id',
                        element: (
                            <RouteGuard
                                allowedActions={ROLE_ACTIONS.READ}
                                subject="BillingRuns"
                            >
                                <BillingRunView />
                            </RouteGuard>
                        ),
                    },
                ],
            },
            {
                path: pages.reports.path,
                children: [
                    {
                        path: '',
                        index: true,
                        element: (
                            <RouteGuard
                                allowedActions={ROLE_ACTIONS.READ}
                                subject="Reports"
                            >
                                <Reports />
                            </RouteGuard>
                        ),
                    },
                ],
            },
            {
                path: pages.integrations.path,
                children: [
                    {
                        path: '',
                        index: true,
                        element: <Integrations />,
                    },
                ],
            },
            {
                path: pages.emailIntegrationLogs.path,
                children: [
                    {
                        path: '',
                        index: true,
                        element: (
                            <RouteGuard
                                allowedActions={ROLE_ACTIONS.MANAGE}
                                subject="all"
                            >
                                <EmailIntegrationLogs />
                            </RouteGuard>
                        ),
                    },
                ],
            },
        ],
    },
]

export default routes
