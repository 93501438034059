import { Close, CheckCircle } from "@mui/icons-material";
import {
  Button,
  ButtonProps,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

interface Props {
  open: boolean;
  title: string;
  description: string;
  cancelProps: ButtonProps;
  confirmProps: ButtonProps;
}

const ConfirmationDialog = ({
  open,
  title,
  description,
  cancelProps,
  confirmProps,
}: Props) => {
  /* Hooks & Use Effects */

  /* States */

  /* API Calls */

  /* Functions & Functional Components */

  return (
    <Dialog open={open} onClose={cancelProps.onClick}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{description}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button {...cancelProps} endIcon={cancelProps.endIcon ?? <Close />}>
          {cancelProps.children ?? "Cancel"}
        </Button>
        <Button
          {...confirmProps}
          endIcon={confirmProps.endIcon ?? <CheckCircle />}
          variant={confirmProps.variant ?? "outlined"}
        >
          {confirmProps.children ?? "Confirm"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;
