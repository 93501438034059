import React, { ReactNode } from 'react'
import { useOrganisation } from 'src/hooks/useOrganisation'
import useRemoteConfig from 'src/hooks/useRemoteConfig'

interface Props {
    flag: string
    feature?: string
    children: ReactNode
}

const FBRemoteConfigWrapper: React.FC<Props> = ({
    flag,
    feature,
    children,
}) => {
    //TODO: Make this super generic for any remote flag

    //If eve is dev, show all features
    if (process.env.REACT_APP_DEPLOYMENT_ENV === 'development') {
        return <>{children}</>
    }

    const value = useRemoteConfig(flag, feature)

    if (flag === 'remove_switch_references') {
        const super_whitelabelled_orgs: Array<String> = useRemoteConfig(
            flag,
            feature
        )
        const organisation = useOrganisation()

        if (!super_whitelabelled_orgs) {
            return undefined
        }

        if (!super_whitelabelled_orgs?.includes(organisation)) {
            return <>{children}</>
        }

        return undefined
    } else {
        return value ? <>{children}</> : undefined
    }
}

export default FBRemoteConfigWrapper
