import { alpha, createTheme, darken, lighten } from '@mui/material'

// Brand primary colors
// The colour palette is built with three main colours as its foundation used for main CTA’s and content.
const switchGreen = '#046234'
const switchGreenLighter = '#FF2727'
const switchGreenDarker20 = '#297957'
const switchGreenDarker40 = '#21573D'
const switchGreenDarker60 = '#193825'
const switchGreenDarker80 = '#111C0F'
const white = '#FFFFFF'

// Brand secondary colors
// Used in branding, alternative tags, illustrations, icons, gradients and data visualisation.
const charcoalGrey = '#383838'
const blue = '#269AFF'
const orange = '#F87700'
const green = '#009A4F'

// Semantic colors
const successGreen = '#58DD5E'
const warningOrange = '#FFBA17'
const errorRed = '#F92C2C'

// Neutral colors
const black = '#000000'
const blackLighter20 = '#000000CC'
const blackLighter40 = '#00000099'
const blackLighter60 = '#00000040'
const blackLighter80 = '#0000001A'
const grey = '#343434'
const greyLighter20 = '#727272'
const greyLighter40 = '#9B9B9B'
const greyLighter60 = '#CFCFCF'
const greyLighter80 = '#F9F9FB'
const greyLighter90 = '#f8f9fa'

// Nav and background
const neutralGrey = '#C6C6D7'

// Typography
const fontFamily = 'Comfortaa, Arial'

export const themeColors = {
    primary: '#046234',
    secondary: '#383838',
    success: '#66bb6a',
    warning: '#FFBA17',
    error: '#F92C2C',
    info: '#269AFF',
    black: '#000000',
    white: '#FFFFFF',
}

export const colors = {
    gradients: {
        blue1: 'linear-gradient(135deg, #6B73FF 0%, #000DFF 100%)',
        blue2: 'linear-gradient(135deg, #ABDCFF 0%, #0396FF 100%)',
        blue3: 'linear-gradient(127.55deg, #141E30 3.73%, #243B55 92.26%)',
        blue4: 'linear-gradient(-20deg, #2b5876 0%, #4e4376 100%)',
        blue5: 'linear-gradient(135deg, #97ABFF 10%, #123597 100%)',
        orange1: 'linear-gradient(135deg, #FCCF31 0%, #F55555 100%)',
        orange2: 'linear-gradient(135deg, #FFD3A5 0%, #FD6585 100%)',
        orange3: 'linear-gradient(120deg, #f6d365 0%, #fda085 100%)',
        purple1: 'linear-gradient(135deg, #43CBFF 0%, #9708CC 100%)',
        purple3: 'linear-gradient(135deg, #667eea 0%, #764ba2 100%)',
        pink1: 'linear-gradient(135deg, #F6CEEC 0%, #D939CD 100%)',
        pink2: 'linear-gradient(135deg, #F761A1 0%, #8C1BAB 100%)',
        green1: 'linear-gradient(135deg, #FFF720 0%, #3CD500 100%)',
        green2: 'linear-gradient(to bottom, #00b09b, #96c93d)',
        black1: 'linear-gradient(100.66deg, #434343 6.56%, #000000 93.57%)',
        black2: 'linear-gradient(60deg, #29323c 0%, #485563 100%)',
    },
    shadows: {
        success:
            '0px 1px 4px rgba(68, 214, 0, 0.25), 0px 3px 12px 2px rgba(68, 214, 0, 0.35)',
        error: '0px 1px 4px rgba(255, 25, 67, 0.25), 0px 3px 12px 2px rgba(255, 25, 67, 0.35)',
        info: '0px 1px 4px rgba(51, 194, 255, 0.25), 0px 3px 12px 2px rgba(51, 194, 255, 0.35)',
        primary:
            '0px 1px 4px rgba(85, 105, 255, 0.25), 0px 3px 12px 2px rgba(85, 105, 255, 0.35)',
        warning:
            '0px 1px 4px rgba(255, 163, 25, 0.25), 0px 3px 12px 2px rgba(255, 163, 25, 0.35)',
        card: '0px 9px 16px rgba(159, 162, 191, .18), 0px 2px 2px rgba(159, 162, 191, 0.32)',
        cardSm: '0px 2px 3px rgba(159, 162, 191, .18), 0px 1px 1px rgba(159, 162, 191, 0.32)',
        cardLg: '0 5rem 14rem 0 rgb(255 255 255 / 30%), 0 0.8rem 2.3rem rgb(0 0 0 / 60%), 0 0.2rem 0.3rem rgb(0 0 0 / 45%)',
    },
    layout: {
        general: {
            backgroundColor: '#f4f4f4',
            borderRadiusSm: '6px',
            borderRadius: '10px',
            borderRadiusLg: '15px',
            borderRadiusXl: '16px',
        },
        sidebar: {
            background: themeColors.error,
            textColor: themeColors.black,
            dividerBg: '#f2f5f9',
            menuItemColor: themeColors.black,
            menuItemColorActive: themeColors.black,
            menuItemBg: themeColors.error,
            menuItemBgActive: themeColors.black,
            menuItemIconColor: themeColors.black,
            menuItemIconColorActive: themeColors.black,
            menuItemHeadingColor: themeColors.secondary,
        },
        dataTable: {
            headerBg: 'rgb(250, 250, 250)',
            headerBorderColor: 'rgb(230, 235, 241)',
            rowBg: 'rgb(255, 255, 255);',
            borderColor: 'rgb(230, 235, 241)',
        },
    },
    alpha: {
        white: {
            5: alpha(themeColors.white, 0.02),
            10: alpha(themeColors.white, 0.1),
            30: alpha(themeColors.white, 0.3),
            50: alpha(themeColors.white, 0.5),
            70: alpha(themeColors.white, 0.7),
            100: themeColors.white,
        },
        trueWhite: {
            5: alpha(themeColors.white, 0.02),
            10: alpha(themeColors.white, 0.1),
            30: alpha(themeColors.white, 0.3),
            50: alpha(themeColors.white, 0.5),
            70: alpha(themeColors.white, 0.7),
            100: themeColors.white,
        },
        black: {
            5: alpha(themeColors.black, 0.02),
            10: alpha(themeColors.black, 0.1),
            30: alpha(themeColors.black, 0.3),
            50: alpha(themeColors.black, 0.5),
            70: alpha(themeColors.black, 0.7),
            100: themeColors.black,
        },
    },
    secondary: {
        lighter: lighten(themeColors.secondary, 0.85),
        light: lighten(themeColors.secondary, 0.25),
        main: themeColors.secondary,
        dark: darken(themeColors.secondary, 0.2),
    },
    primary: {
        lighter: lighten(themeColors.primary, 0.85),
        light: lighten(themeColors.primary, 0.3),
        main: themeColors.primary,
        dark: darken(themeColors.primary, 0.2),
    },
    success: {
        lighter: lighten(themeColors.success, 0.85),
        light: lighten(themeColors.success, 0.3),
        main: themeColors.success,
        dark: darken(themeColors.success, 0.2),
    },
    warning: {
        lighter: lighten(themeColors.warning, 0.85),
        light: lighten(themeColors.warning, 0.3),
        main: themeColors.warning,
        dark: darken(themeColors.warning, 0.2),
    },
    error: {
        lighter: lighten(themeColors.error, 0.85),
        light: lighten(themeColors.error, 0.3),
        main: themeColors.error,
        dark: darken(themeColors.error, 0.2),
    },
    info: {
        lighter: lighten(themeColors.info, 0.85),
        light: lighten(themeColors.info, 0.3),
        main: themeColors.info,
        dark: darken(themeColors.info, 0.2),
    },
}

export const LightTheme = createTheme({
    // brand colors for custom components
    colors: {
        neutral: {
            white: {
                20: white,
                40: white,
                60: white,
                80: white,
                100: white,
            },
            grey: {
                20: greyLighter90,
                40: greyLighter60,
                60: greyLighter40,
                80: greyLighter20,
                100: grey,
            },
            black: {
                20: blackLighter80,
                40: blackLighter60,
                60: blackLighter40,
                80: blackLighter20,
                100: black,
            },
        },
        alpha: {
            white: {
                5: alpha(themeColors.white, 0.02),
                10: alpha(themeColors.white, 0.1),
                30: alpha(themeColors.white, 0.3),
                50: alpha(themeColors.white, 0.5),
                70: alpha(themeColors.white, 0.7),
                100: themeColors.white,
            },
            trueWhite: {
                5: alpha(themeColors.white, 0.02),
                10: alpha(themeColors.white, 0.1),
                30: alpha(themeColors.white, 0.3),
                50: alpha(themeColors.white, 0.5),
                70: alpha(themeColors.white, 0.7),
                100: themeColors.white,
            },
            black: {
                5: alpha(themeColors.black, 0.02),
                10: alpha(themeColors.black, 0.1),
                30: alpha(themeColors.black, 0.3),
                50: alpha(themeColors.black, 0.5),
                70: alpha(themeColors.black, 0.7),
                100: themeColors.black,
            },
        },
        gradients: {
            blue1: 'linear-gradient(135deg, #6B73FF 0%, #000DFF 100%)',
            blue2: 'linear-gradient(135deg, #ABDCFF 0%, #0396FF 100%)',
            blue3: 'linear-gradient(127.55deg, #141E30 3.73%, #243B55 92.26%)',
            blue4: 'linear-gradient(-20deg, #2b5876 0%, #4e4376 100%)',
            blue5: 'linear-gradient(135deg, #97ABFF 10%, #123597 100%)',
            orange1: 'linear-gradient(135deg, #FCCF31 0%, #F55555 100%)',
            orange2: 'linear-gradient(135deg, #FFD3A5 0%, #FD6585 100%)',
            orange3: 'linear-gradient(120deg, #f6d365 0%, #fda085 100%)',
            purple1: 'linear-gradient(135deg, #43CBFF 0%, #9708CC 100%)',
            purple3: 'linear-gradient(135deg, #667eea 0%, #764ba2 100%)',
            pink1: 'linear-gradient(135deg, #F6CEEC 0%, #D939CD 100%)',
            pink2: 'linear-gradient(135deg, #F761A1 0%, #8C1BAB 100%)',
            green1: 'linear-gradient(135deg, #FFF720 0%, #3CD500 100%)',
            green2: 'linear-gradient(to bottom, #00b09b, #96c93d)',
            black1: 'linear-gradient(100.66deg, #434343 6.56%, #000000 93.57%)',
            black2: 'linear-gradient(60deg, #29323c 0%, #485563 100%)',
        },
        primary: {
            lighter: lighten(themeColors.primary, 0.85),
            light: lighten(themeColors.primary, 0.3),
            main: themeColors.primary,
            dark: darken(themeColors.primary, 0.2),
        },
    },

    // custom component styling
    general: {
        reactFrameworkColor: colors.layout.general.backgroundColor,
        borderRadiusSm: '6px',
        borderRadius: '10px',
        borderRadiusLg: '15px',
        borderRadiusXl: '16px',
    },
    sidebar: {
        background: colors.alpha.white[70],
        textColor: 'red', // TODO, crazy colors for now
        dividerBg: green,
        boxShadow: '',
        width: '290px',
        menuItemColor: '#242E6F',
        menuItemColorActive: themeColors.primary,
        menuItemBg: themeColors.white,
        menuItemBgActive: darken(themeColors.white, 0.1),
        menuItemIconColor: lighten(themeColors.secondary, 0.3),
        menuItemIconColorActive: themeColors.primary,
        menuItemHeadingColor: darken(themeColors.secondary, 0.3),
    },
    header: {
        height: '80px',
        background: colors.alpha.white[70],
        boxShadow: '',
        textColor: white,
    },

    // mui typography
    typography: {
        fontFamily,
        h1: {
            fontWeight: 600,
            fontSize: 35,
        },
        h2: {
            fontWeight: 500,
            fontSize: 30,
        },
        h3: {
            fontWeight: 400,
            fontSize: 20,
            lineHeight: 1.4,
            color: colors.alpha.black[100],
        },
        h4: {
            fontWeight: 300,
            fontSize: 16,
        },
        h5: {
            fontWeight: 200,
            fontSize: 14,
        },
        h6: {
            fontSize: 15,
        },
        body1: {
            fontSize: 14,
        },
        body2: {
            fontSize: 14,
        },
        button: {
            fontWeight: 600,
        },
        caption: {
            fontSize: 13,
            textTransform: 'uppercase',
            color: colors.alpha.black[50],
        },
        subtitle1: {
            fontSize: 14,
            color: colors.alpha.black[70],
        },
        subtitle2: {
            fontWeight: 400,
            fontSize: 15,
            color: colors.alpha.black[70],
        },
        overline: {
            fontSize: 13,
            fontWeight: 700,
            textTransform: 'uppercase',
        },
        // h1: {
        //   fontWeight: 'lighter',
        //   size: '64px',
        //   letterSpacing: '-0.01em',
        // },
        // h2: {
        //   fontWeight: 'lighter',
        //   size: '50px',
        //   letterSpacing: '-0.01em',
        // },
        // h3: {
        //   fontWeight: 'lighter',
        //   size: '40px',
        //   letterSpacing: '-0.02em',
        // },
        // h4: {
        //   fontWeight: 'lighter',
        //   size: '32px',
        //   letterSpacing: '-0.02em',
        // },
        // h5: {
        //   fontWeight: 'lighter',
        //   size: '24px',
        //   letterSpacing: '-0.02em',
        // },
        // h6: {
        //   size: '20px',
        //   letterSpacing: '-0.02em',
        // },
        // body1: {
        //   size: '16px',
        //   letterSpacing: '0.01em',
        //   lineHeight: '1.4em',
        // },
        // body2: {
        //   size: '14px',
        //   letterSpacing: '0.01em',
        //   lineHeight: '1.3em',
        // },
        // button: {
        //   size: '16px',
        //   letterSpacing: '0.03em',
        // },
    },

    // mui default colours
    palette: {
        mode: 'light',
        text: {
            primary: colors.alpha.black[100],
            secondary: colors.alpha.black[70],
            disabled: '#C6C6D7',
        },
        primary: {
            main: colors.primary.main,
            light: lighten(switchGreen, 0.2),
            dark: switchGreenDarker20,
            contrastText: black,
        },
        secondary: {
            main: colors.info.main,
            light: blackLighter20,
            dark: black,
            contrastText: white,
        },
        error: {
            main: errorRed,
            light: lighten(errorRed, 0.2),
            dark: darken(errorRed, 0.2),
            contrastText: white,
        },
        warning: {
            main: warningOrange,
            light: lighten(warningOrange, 0.2),
            dark: darken(warningOrange, 0.2),
            contrastText: white,
        },
        info: {
            main: black,
            light: blackLighter20,
            dark: black,
            contrastText: white,
        },
        success: {
            main: themeColors.success,
            light: lighten(themeColors.success, 0.2),
            dark: darken(themeColors.success, 0.2),
            contrastText: white,
        },
        action: {
            active: colors.primary.main,
            hover: colors.primary.lighter,
            hoverOpacity: 0.1,
            selected: colors.primary.dark,
            selectedOpacity: 0.9,
            disabled: '#C6C6D7',
            disabledBackground: '#F3F3F4',
            disabledOpacity: 0.38,
            focus: colors.primary.main,
            focusOpacity: 0.05,
            activatedOpacity: 0.12,
        },
    },

    // mui component styling
    components: {
        MuiButtonBase: {
            styleOverrides: {
                root: {
                    border: `2px solid #00000000`,
                    '&.MuiButton-root:disabled': {
                        border: `2px solid #00000000`,
                    },
                    '&.MuiButton-root:active': {
                        border: `2px solid #00000000`,
                    },

                    // '&.MuiSwitch-switchBase': {
                    //   padding: '5px',
                    // },
                },
            },
        },
        MuiButton: {
            defaultProps: {
                disableRipple: true,
                disableElevation: true,
            },
            styleOverrides: {
                root: {
                    textTransform: 'none',
                    borderRadius: '20px',
                    fontSize: '0.8em',
                },
                containedPrimary: {
                    color: white,
                    backgroundColor: switchGreen,
                    border: `2px solid ${switchGreen}`,
                    ':hover': {
                        color: black,
                        backgroundColor: white,
                        border: `2px solid ${switchGreen}`,
                    },
                    ':active': {
                        color: white,
                        backgroundColor: blackLighter40,
                        border: `2px solid ${blackLighter40}`,
                    },
                    ':disabled': {
                        color: white,
                        backgroundColor: blackLighter60,
                        border: `2px solid ${blackLighter60}`,
                    },
                },
                outlined: {
                    borderWidth: '2px',
                    ':hover': {
                        border: `2px solid #00000000`,
                    },
                },
                outlinedPrimary: {
                    color: switchGreen,
                    backgroundColor: white,
                    border: `2px solid ${switchGreen}`,
                    ':hover': {
                        color: white,
                        backgroundColor: switchGreen,
                        border: `2px solid ${switchGreen}`,
                    },
                    ':active': {
                        color: white,
                        backgroundColor: blackLighter40,
                        border: `2px solid ${blackLighter40}`,
                    },
                    ':disabled': {
                        color: white,
                        backgroundColor: blackLighter60,
                        border: `2px solid ${blackLighter60}`,
                    },
                },
                outlinedSecondary: {
                    color: blackLighter20,
                    backgroundColor: white,
                    border: `2px solid ${charcoalGrey}`,
                    ':hover': {
                        color: white,
                        backgroundColor: charcoalGrey,
                    },
                    ':active': {
                        color: white,
                        backgroundColor: switchGreen,
                        border: `2px solid ${switchGreen}`,
                    },
                    ':disabled': {
                        color: white,
                        backgroundColor: blackLighter60,
                        border: `2px solid ${blackLighter60}`,
                    },
                },
                containedSecondary: {
                    color: blackLighter20,
                    backgroundColor: white,
                    border: `2px solid ${charcoalGrey}`,
                    ':hover': {
                        color: white,
                        backgroundColor: charcoalGrey,
                    },
                    ':active': {
                        color: white,
                        backgroundColor: switchGreen,
                        border: `2px solid ${switchGreen}`,
                    },
                    ':disabled': {
                        color: white,
                        backgroundColor: blackLighter60,
                        border: `2px solid ${blackLighter60}`,
                    },
                },
                sizeMedium: {
                    padding: '10px 20px',
                },
                sizeSmall: {
                    padding: '6px 14px',
                },
            },
        },
        MuiLink: {
            styleOverrides: {
                root: {
                    '&[disabled]': {
                        color: neutralGrey,
                        pointerEvents: 'none',
                    },
                },
            },
        },
        MuiDialogActions: {
            styleOverrides: {
                root: {
                    padding: ' 0px 24px 20px 24px',
                },
            },
        },
        MuiPaper: {
            defaultProps: {
                elevation: 0,
            },
            styleOverrides: {
                root: {
                    backgroundImage: 'none',
                    '&.MuiPopover-paper': {
                        background: 'white',
                    },
                },

                rounded: {
                    borderRadius: '10px',
                },
            },
        },

        MuiCardHeader: {
            styleOverrides: {
                root: {
                    padding: '24px',
                },
                title: {
                    fontSize: '1.125rem',
                },
            },
        },
        MuiCardContent: {
            styleOverrides: {
                root: {
                    padding: '24px',
                },
            },
        },
        MuiCardActions: {
            styleOverrides: {
                root: {
                    padding: '24px',
                },
            },
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    paddingTop: '5px',
                    paddingBottom: '5px',
                    '&:hover': {
                        borderRadius: colors.layout.general.borderRadius,
                        backgroundColor: colors.primary.light,
                        color: 'white',
                    },
                    '&.Mui-selected': {
                        color: 'white',
                        backgroundColor: colors.primary.light,
                        borderRadius: '10px',
                        '&:hover': {
                            borderRadius: '10px',
                        },
                    },
                },
            },
        },
        MuiCheckbox: {
            styleOverrides: {
                root: {
                    borderRadius: colors.layout.general.borderRadiusSm,
                    padding: '2px',
                    margin: '8px',

                    '&:hover': {
                        borderRaduis: '0px',
                    },
                },
            },
        },
        MuiRadio: {
            styleOverrides: {
                root: {
                    padding: '2px',
                    margin: '8px',

                    '&:hover': {
                        borderRaduis: '0px',
                    },
                },
            },
        },
        MuiSwitch: {
            defaultProps: {
                disableRipple: true,
            },
            styleOverrides: {
                root: {
                    width: 42,
                    height: 26,
                    padding: 0,
                    margin: 5,
                },
                switchBase: {
                    padding: 0,
                    // margin: 2,
                    transitionDuration: '300ms',
                    '&.Mui-checked': {
                        transform: 'translateX(16px)',
                        color: '#fff',
                        '& + .MuiSwitch-track': {
                            backgroundColor: '#65C466',
                            opacity: 1,
                            border: 0,
                        },
                        '&.Mui-disabled + .MuiSwitch-track': {
                            opacity: 0.5,
                        },
                    },
                    '&.Mui-focusVisible .MuiSwitch-thumb': {
                        color: '#33cf4d',
                        border: '6px solid #fff',
                    },
                    '&.Mui-disabled .MuiSwitch-thumb': {
                        color: grey[100],
                    },
                    '&.Mui-disabled + .MuiSwitch-track': {
                        opacity: 0.7,
                    },
                },

                thumb: {
                    boxSizing: 'border-box',
                    width: 22,
                    height: 22,
                },
                track: {
                    borderRadius: 26 / 2,
                    backgroundColor: '#E9E9EA',
                    opacity: 1,
                },
            },
        },
        MuiAlert: {
            defaultProps: {
                variant: 'outlined',
            },
        },
        MuiSelect: {
            defaultProps: {
                variant: 'outlined',
                notched: true,
            },

            styleOverrides: {
                outlined: {
                    borderRadius: '15px',

                    '&:hover': {
                        borderRadius: '15px',
                    },
                },
            },
        },
        MuiAutocomplete: {
            defaultProps: {
                slotProps: {
                    paper: {
                        elevation: 8,
                    },
                },
            },
        },
        MuiSlider: {
            styleOverrides: {
                root: {},

                valueLabel: {
                    color: 'black',
                    backgroundColor: 'transparent',
                },
            },
        },
        MuiChip: {
            styleOverrides: {
                root: {
                    '&.MuiChip-deletable .MuiChip-deleteIcon': {
                        color: 'inherit',
                    },
                    '&.Mui-disabled': {
                        opacity: 1,
                    },
                },
                filled: {
                    color: 'white',
                },
            },
        },
        MuiInputBase: {
            styleOverrides: {
                input: {
                    '&::placeholder': {
                        color: '#5D616B',
                        fontSize: '0.875rem',
                    },
                },
            },
        },
        MuiInputLabel: {
            defaultProps: {
                shrink: true,
            },
            styleOverrides: {
                root: {
                    color: '#000',
                    '&.Mui-disabled': {
                        color: '#000',
                    },
                },
                outlined: {
                    '&.MuiInputLabel-sizeSmall': {
                        lineHeight: '1em',
                    },
                    '&.MuiInputLabel-shrink': {
                        background: 'white',
                        padding: '0 8px',
                        marginLeft: -6,
                        lineHeight: '1.4375em',
                    },
                    '&.Mui-focused': {
                        color: '#000',
                        fontWeight: 700,
                        '&.Mui-error': {
                            color: colors.error.main,
                        },
                    },
                },
            },
        },
        MuiInput: {
            styleOverrides: {
                root: {
                    '&.Mui-disabled:before': {
                        borderBottomStyle: 'solid',
                        borderBottomWidth: 0,
                    },
                },
                input: {
                    '&.Mui-disabled': {
                        '-webkit-text-fill-color': '#000',
                    },
                },
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    borderRadius: '15px',
                    '& .MuiOutlinedInput-notchedOutline': {
                        borderRadius: '15px',
                    },

                    '&.MuiInputBase-multiline': {
                        padding: 1,
                    },
                },
                disabled: {
                    backgroundColor: 'red',
                    padding: '100px',
                },
                input: {
                    fontWeight: 500,
                    background: 'white',
                    padding: '15.5px 16px',

                    borderRadius: '15px',
                    '&.MuiInputBase-inputSizeSmall': {
                        padding: '10px 14px',
                        '&.MuiInputBase-inputAdornedStart': {
                            paddingLeft: 0,
                        },
                    },
                },
                inputAdornedStart: {
                    paddingLeft: 4,
                },
                notchedOutline: {
                    borderRadius: '15px',
                },
            },
        },
    },
})
