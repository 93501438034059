export const TrueFalseOptions = [
    {
        _id: 'true',
        name: 'True',
    },
    {
        _id: 'false',
        name: 'False',
    },
]

export const EnabledDisabledOptions = [
    {
        _id: 'true',
        name: 'Enabled',
    },
    {
        _id: 'false',
        name: 'Disabled',
    },
]

export enum BucketUnit {
    Minute = 'minute',
    Hour = 'hour',
    Day = 'day',
    Month = 'month',
    Year = 'year',
}

export enum AggregateP1SplitGroupBy {
    Application = 'application',
    Bucket = 'bucket',
}

export enum OrderByDir {
    Desc = 'desc',
    Asc = 'asc',
}

export enum DurationUnit {
    Years = 'years',
    Months = 'months',
    Weeks = 'weeks',
    Days = 'days',
    Hours = 'hours',
    Minutes = 'minutes',
    Seconds = 'seconds',
}

export enum ORGANISATION {
    SWITCH = 'switch',
    METER_MATE = 'metermate',
    HYDRO_WELLNESS = 'hydrowellness',
    JOAT = 'joat',
    MANUFLO = 'manuflo',
    EXPROP = 'exprop',
    NULEAF = 'nuleaf',
}

export enum STATUS {
    PENDING = 'pending',
    SUCCESS = 'success',
    WARNING = 'warning',
    ERROR = 'error',
}
